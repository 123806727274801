import demo from '../assets/demo.mp4';
import frame from '../assets/iphone-frame.png';

const PhoneFrame = () => {
  return (
    <div className="relative w-96">
      <img src={frame} alt="Phone Frame" className="frame-image" />
      <div className="video-container">
        <video src={demo} className="video-content" autoPlay loop muted />
      </div>
    </div>
  );
};

export default PhoneFrame;
