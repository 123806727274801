import { useState } from "react";

export function Contact() {
  const [message, setMessage] = useState("");

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const data = {
      name: e.target.elements.name.value,
      email: e.target.elements.email.value,
      subject: e.target.elements.subject.value,
      message: e.target.elements.message.value,
    };

    const response = await fetch('https://api.trinityapp.io/contact', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    });
    if (response.status === 200) {
      e.target.reset();
      setMessage("Thanks for contacting us");
    } else {
      setMessage("Unable to send message");
    }
  };

  return (
    <div>
      <h1>Contact Us</h1>
      <div className="bg-white bg-opacity-50">
        <form onSubmit={handleSubmit} id="contact-form">
          <div className="flex flex-col w-96 p-12">
            <div>
              <label htmlFor="name">
                Name
              </label>
              <div className="my-2">
                <input
                  type="text"
                  name="name"
                  id="name"
                  autoComplete="name"
                  required
                />
              </div>
            </div>
            <div>
              <label htmlFor="email">
                Email
              </label>
              <div className="my-2">
                <input
                  type="email"
                  name="email"
                  id="email"
                  autoComplete="email"
                  required
                />
              </div>
            </div>
            <div>
              <label htmlFor="subject">
                Subject
              </label>
              <div className="my-2">
                <input
                  type="text"
                  name="subject"
                  id="subject"
                  required
                />
              </div>
            </div>
            <div>
              <label htmlFor="message">
                Message
              </label>
              <div className="my-2">
                <textarea
                  name="message"
                  id="message"
                  rows={4}
                  defaultValue={''}
                  required
                />
              </div>
            </div>
            <p className="h-8 text-gray-500">
              { message }
            </p>
            <div className="mt-4">
              <button
                type="submit"
                className="block w-full rounded-md bg-primary px-3 py-2 text-center text-sm font-semibold text-black shadow-sm hover:cursor-pointer"
              >
                Send Message
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
