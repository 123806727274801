import video from '../assets/background.mp4';

const VideoBackground = ( { children }: any ) => {
  return (
    <div className="video-background-container">
      <video autoPlay loop muted className="video-background">
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="content">
        {children}
      </div>
    </div>
  );
};

export default VideoBackground;
